import React from 'react'

import PropTypes from 'prop-types'

import styles from '../modules/info-text.module.css'

const InfoText = (props) => {
  return (
    <div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
      {props.icon}
      <span>{props.text}</span>
    </div>
  )
}

InfoText.defaultProps = {
  rootClassName: '',
  text: 'info@fysiotherapiebeter.nl',
}

InfoText.propTypes = {
  rootClassName: PropTypes.string,
  text: PropTypes.string,
}

export default InfoText
