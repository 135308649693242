const WhatsApp = ({color}) => {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M10.2076 9.42501C10.033 9.33768 9.17662 8.91683 9.01719 8.85822C8.85776 8.80019 8.7417 8.77147 8.62506 8.94614C8.509 9.11963 8.17549 9.51235 8.07409 9.6284C7.9721 9.74505 7.87069 9.75911 7.69661 9.67237C7.52253 9.58444 6.961 9.40098 6.29574 8.80781C5.77817 8.34593 5.42825 7.77561 5.32685 7.60094C5.22544 7.42686 5.31571 7.33249 5.40304 7.24574C5.48159 7.16779 5.57713 7.04235 5.66446 6.94095C5.7518 6.83896 5.78052 6.76628 5.83855 6.64964C5.89716 6.53358 5.86785 6.43218 5.82389 6.34485C5.78052 6.25751 5.43235 5.39999 5.28699 5.05123C5.14573 4.71186 5.00212 4.75816 4.89545 4.7523C4.79346 4.74761 4.6774 4.74644 4.56135 4.74644C4.44529 4.74644 4.25655 4.78981 4.09712 4.96448C3.93711 5.13857 3.48754 5.56 3.48754 6.41753C3.48754 7.27446 4.11119 8.10268 4.19852 8.21932C4.28586 8.33538 5.42649 10.095 7.17377 10.8493C7.58993 11.0287 7.91407 11.136 8.1667 11.2157C8.58403 11.3487 8.96385 11.33 9.26395 11.2848C9.59805 11.235 10.2944 10.8634 10.4397 10.4566C10.5845 10.0498 10.5845 9.70109 10.5412 9.6284C10.4978 9.55572 10.3817 9.51235 10.2071 9.42501H10.2076ZM7.02958 13.7642H7.02724C5.98944 13.7644 4.97069 13.4854 4.07778 12.9565L3.86677 12.8311L1.67343 13.4067L2.25899 11.2684L2.12124 11.0492C1.54104 10.1257 1.234 9.05679 1.23558 7.96611C1.23676 4.77164 3.8357 2.1727 7.03193 2.1727C8.57934 2.1727 10.0341 2.77642 11.1279 3.87133C11.6673 4.40858 12.0949 5.04746 12.3859 5.75103C12.6769 6.45459 12.8254 7.20886 12.823 7.97021C12.8218 11.1647 10.2229 13.7642 7.02958 13.7642ZM11.9602 3.0396C11.3144 2.38952 10.546 1.87408 9.6995 1.52314C8.85303 1.17219 7.94533 0.992728 7.029 0.995141C3.18743 0.995141 0.0597863 4.1222 0.0586141 7.96552C0.0580279 9.19407 0.378647 10.3933 0.988819 11.4501L0 15.0625L3.69503 14.093C4.71719 14.6499 5.86263 14.9417 7.02665 14.9418H7.02958C10.8711 14.9418 13.9988 11.8147 14 7.9708C14.0028 7.05482 13.824 6.14738 13.4739 5.30095C13.1237 4.45452 12.6093 3.68592 11.9602 3.0396Z"
                  fill={color}/>
        </svg>
    )
}

WhatsApp.defaultProps = {
    color: '#F8F8F8'
}

export default WhatsApp
