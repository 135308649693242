import styles from '../modules/expertises.module.css'
import BulletPoint from './BulletPoint';

const Expertises = () => {
    return (
        <div className={styles['wrapper']}>
            <h2 className={styles['heading']}>Expertises</h2>

            <div className={styles['content-wrapper']}>
                <div>
                    <p>Wij vinden het belangrijk dat iedereen gezien en geholpen wordt. Voor de volgende orthopedische klachten kan een fysiotherapeut iets voor u betekenen: </p>

                    <div className={styles['bullet-points-wrapper']}>
                        <div>
                            <BulletPoint punt="Hoofdpijn" />
                            <BulletPoint punt="Nekklachten" />
                            <BulletPoint punt="Schouderklachten" />
                            <BulletPoint punt="Elleboogklachten" />
                            <BulletPoint punt="Pols- en handklachten" />
                            <BulletPoint punt="Rugklachten" />
                            <BulletPoint punt="Revalidatie na OK" />
                        </div>

                        <div>
                            <BulletPoint punt="Bekkenklachten" />
                            <BulletPoint punt="Heupklachten" />
                            <BulletPoint punt="Knieklachten" />
                            <BulletPoint punt="Scheenbeenklachten" />
                            <BulletPoint punt="Kuitklachten" />
                            <BulletPoint punt="Enkelklachten" />
                            <BulletPoint punt="Draaiduizeligheid (BPPD)" />
                        </div>
                    </div>
                </div>

                <div>
                    <p>Voor de volgende aandoeningen kan een fysiotherapeut u helpen om uw dagelijkse activiteiten makkelijker of zo goed als mogelijk uit te voeren.</p>

                    <div className={styles['bullet-points-wrapper']}>
                        <div>
                            <BulletPoint punt="Artrose" />
                            <BulletPoint punt="Beroertes/CVA" />
                            <BulletPoint punt="COPD" />
                            <BulletPoint punt="Corona revalidatie" />
                            <BulletPoint punt="Diabetes mellitus" />
                        </div>

                        <div>
                            <BulletPoint punt="Hart- en vaatziekten" />
                            <BulletPoint punt="Obesitas" />
                            <BulletPoint punt="Oncologische revalidatie" />
                            <BulletPoint punt="Reuma" />
                            <BulletPoint punt="Fibromyalgie" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expertises;