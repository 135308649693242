import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import logo from '../assets/logo.svg'

import styles from '../modules/navigation.module.css'

const Navigation = (props) => {


	const width = window.innerWidth
	const breakPoint = 670

	let [isOpen, setIsOpen] = useState(width < breakPoint ? false : true)

	const toggle = () => {
		if (width < breakPoint) {
			setIsOpen(!isOpen)
			console.log(isOpen);
		}
	}

	const closeNav = () => {
		if (width < breakPoint) {
			setIsOpen(isOpen = false)
			console.log(isOpen);
		}
	}

	return (
		<div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
			<div className={styles['nav-wrapper']}>
				<Link to="/" className={styles['navlink']}>
					<img
						alt="Fysiotherapiebeter Logo"
						src={logo}
						className={styles['image']}
					/>
				</Link>

				<span className={`${styles['toggle']} ${(isOpen && width < breakPoint) ? styles['toggle--opened'] : ''}`} onClick={() => toggle()}>
					<span className={styles['top']}></span>
					<span className={styles['middle']}></span>
					<span className={styles['bottom']}></span>
				</span>

				<nav className={styles['navigation']} style={{ height: (width < breakPoint && isOpen) ? '200px' : width > breakPoint ? 'auto' : '0px' }}>
					<Link to={`/behandelingen`} className={styles['link']} onClick={() => closeNav}>
						Behandelingen
						{props.active === 'behandelingen' && <span className={styles['border']} style={{ width: '102px' }}></span>}
					</Link>
					<Link to={`/diensten`} className={styles['link']} onClick={() => closeNav}>
						Diensten
						{props.active === 'diensten' && <span className={styles['border']} style={{ width: '57px' }}></span>}
					</Link>
					<Link to={`/overons`} className={styles['link']} onClick={() => closeNav}>
						Over ons
						{props.active === 'overons' && <span className={styles['border']} style={{ width: '62px' }}></span>}
					</Link>
					<Link to={`/contactons`} className={styles['link']} onClick={() => closeNav}>
						Contact
						{props.active === 'contactons' && <span className={styles['border']} style={{ width: '53px' }}></span>}
					</Link>
				</nav>
			</div>
		</div>
	)
}

Navigation.defaultProps = {
	rootClassName: '',
	active: ''
}

Navigation.propTypes = {
	rootClassName: PropTypes.string,
	active: PropTypes.string,
}

export default Navigation
