import React from 'react'

import PropTypes from 'prop-types'

import projectStyles from '../style.module.css'
import styles from '../modules/card.module.css'
import {Link} from 'react-router-dom'

const Card = (props) => {
    return (
        <div
            className={` ${styles['container']} ${projectStyles['content']} ${styles[props.rootClassName]
            } `}
        >
            <div className={styles['container1']}>
                <div className={styles['container2']}>
                    <img
                        alt={props.image_alt}
                        src={props.image_src}
                        className={styles['image']}
                    />
                    <span className={styles['name']}>{props.name}</span>
                </div>
                <Link className={` ${styles['button']} ${projectStyles['button']} `}
                      to={props.url}>{props.button}</Link>
            </div>
        </div>
    )
}

Card.defaultProps = {
    image_alt: 'image',
    text1: 'BIG: 39920340504',
    image_src: '/playground_assets/thomas-300h.png',
    button: 'Lees meer',
    rootClassName: '',
}

Card.propTypes = {
    image_alt: PropTypes.string,
    text1: PropTypes.string,
    image_src: PropTypes.string,
    button: PropTypes.string,
    rootClassName: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
}

export default Card
