import React, { useRef, useState } from 'react'

import { Helmet } from 'react-helmet'

import emailjs from '@emailjs/browser'

import Navigation from '../../components/navigation'
import Header from '../../components/header'
import Footer from '../../components/footer'
import projectStyles from '../../style.module.css'
import styles from './contactons.module.css'
import header from '../../assets/headers/contact.png'
import InfoText from '../../components/info-text'
import Phone from '../../components/icons/Phone'
import Envelope from '../../components/icons/Envelope'
import { RightAddress, LeftAddress } from '../../data/addresses'
import Map from '../../components/MapBox/Map'

const Index = () => {

    const [isSend, setIsSent] = useState(false);

    const form = useRef();
    const name = useRef();
    const email_adres = useRef();
    const telefoon = useRef();
    const onderwerp = useRef();
    const bericht = useRef();

    const sendEmail = e => {
        e.preventDefault()

        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, form.current, process.env.REACT_APP_PUBLIC_KEY)
            .then((result) => {
                setIsSent(true)

                name.current.value = ''
                email_adres.current.value = ''
                telefoon.current.value = ''
                onderwerp.current.value = ''
                bericht.current.value = ''
            }, error => {
                console.log(error.text);
            })
    }
    return (
        <div className={styles['container']}>
            <Helmet>
                <title>Contact ons - Fysiotherapie beter</title>
                <meta property="og:title" content="Contact ons - Fysiotherapie beter" />
            </Helmet>
            <Navigation active="contactons" />
            <Header
                text="Wij leveren fysiotherapeutische zorg, waarbij we in samenspraak met de patiënt, zorgen dat patiënten weer kunnen doen wat ze willen doen."
                heading="Fysiotherapie Beter"
                rootClassName="rootClassName"
                image={header} />
            <div className={styles['container01']}>
                <div className={styles['container02']}>

                    <form ref={form} onSubmit={sendEmail} className={styles['container03']}>
                        <div className={styles['heading-section']}>
                            <h1 className={styles['text']}>Afspraak maken</h1>
                            <p>Neem gerust contact met ons op als u vragen heeft of een afspraak wilt maken. </p>
                        </div>

                        {isSend ? (
                            <p className={styles['alert--success']}>Bedankt voor uw bericht</p>

                        ) : (
                            <div className={styles['alert--success--invisible']}></div>
                        )}

                        <div className={styles["contact-form-heading"]}>
                            <h2 className={projectStyles['sub-heading']}>Aanmeldformulier</h2>
                        </div>
                        <div className={styles['input-wrapper']}>
                            <label htmlFor='name'>Voor- en achternaam:</label>
                            <input
                                type="text"
                                className={` ${styles['input']} ${projectStyles['input']} `}
                                id='name'
                                name='name'
                                ref={name}
                            />
                        </div>
                        <div className={styles['input-wrapper']}>
                            <label htmlFor='email_adres'>Emailadres:</label>
                            <input
                                type="text"
                                className={` ${styles['input']} ${projectStyles['input']} `}
                                id='email_adres'
                                name='email_adres'
                                ref={email_adres}
                            />
                        </div>
                        <div className={styles['input-wrapper']}>
                            <label htmlFor='telefoon'>Telefoonnummer:</label>
                            <input
                                type="tel"
                                className={` ${styles['input']} ${projectStyles['input']} `}
                                id='telefoon'
                                name='telefoon'
                                ref={telefoon}
                            />
                        </div>
                        <div className={styles['input-wrapper']}>
                            <label htmlFor='onderwerp'>Onderwerp:</label>
                            <input
                                type="text"
                                className={` ${styles['input']} ${projectStyles['input']} `}
                                id='onderwerp'
                                name='onderwerp'
                                ref={onderwerp}
                            />
                        </div>
                        <div className={styles['input-wrapper']}>
                            <label htmlFor='bericht'>Bericht:</label>
                            <textarea
                                className={` ${styles['textarea']} ${projectStyles['textarea']} `}
                                id='bericht'
                                name='bericht'
                                ref={bericht}
                            ></textarea>
                        </div>
                        <button onClick={() => console.log('Hello world')} className={`${styles['button']} ${projectStyles['button']}`}>Verzenden</button>
                    </form>
                </div>
                <div className={styles['openingstijden']}>
                    <div className={styles['Container09']}>
                        <h1 className={` ${projectStyles['sub-heading']} `}>Contact</h1>
                        <div className={styles['container10']}>
                            <span className={styles['text06']}>
                                <span></span>
                                <span>Fysiotherapie Beter</span>
                                <br></br>
                                <span>KvK: 30265149</span>
                                <span></span>
                            </span>

                            <div style={{ margin: '10px 0' }}>
                                <p style={{ margin: '2px 0' }}>Hoofdlocatie: Giessenburg</p>
                                <p style={{ margin: '2px 0' }}>Kastanjeplein 11, 3381 LT</p>
                            </div>
                            
                            <div style={{ margin: '10px 0' }}>
                                <p style={{ margin: '2px 0' }}>Dependance: Gorinchem</p>
                                <p style={{ margin: '2px 0' }}>Westwagenstraat 69, 4201 HE</p>
                            </div>
                            
                            <div style={{ margin: '10px 0' }}>
                                <p style={{ margin: '2px 0' }}>Dependance: Molenaarsgraaf</p>
                                <p style={{ margin: '2px 0' }}>Graafdijk Oost 37, 2973 XC</p>
                            </div>

                            <InfoText rootClassName="rootClassName" icon={<Phone color="#347A81" />} text='0184 745906' />
                            <InfoText rootClassName="rootClassName" icon={<Envelope color="#347A81" />} />
                        </div>
                    </div>

                    <div>
                        <h1 className={` ${projectStyles['sub-heading']} ${styles['contact']} `}>Openingstijden</h1>
                        <p className={ styles['availability-sentence']}>Wij zijn het best te bereiken op maandag, woensdag en donderdagochtend van 08.30-12.30. Dit in verband met de aanwezigheid van onze administratieve medewerkster</p>

                        <div className={styles['contact-wrapper']} style={{ marginTop: '45px' }}>
                            <div className={styles['tijden']}>
                                <p>Maandag</p>
                                <p>Dinsdag</p>
                                <p>Woensdag</p>
                                <p>Donderdag</p>
                                <p>Vrijdag</p>
                            </div>
                            <div className={styles['tijden']}>
                                <p>08:00 - 17:30</p>
                                <p>08:00 - 20:30</p>
                                <p>08:00 - 20:30</p>
                                <p>08:00 - 17:30</p>
                                <p>08:00 - 17:00</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['container12']}>
                <div className={styles['wrapper']}>
                    <h1 className={`${projectStyles['sub-heading']} ${styles['onze-locaties']}`}>Onze locaties</h1>
                    <div className={styles['maps-wrapper']}>
                        <Map coords={LeftAddress.coords} address={LeftAddress.address} />
                        <Map coords={RightAddress.coords} address={RightAddress.address} />
                    </div>
                </div>
            </div>
            <Footer rootClassName="rootClassName1"></Footer>
        </div>
    )
}

export default Index
