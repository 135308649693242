import React, { useRef, useEffect, useState } from 'react';

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
// import MapboxDirections from '@mapbox/mapbox-gl-directions/src/directions';

import 'mapbox-gl/dist/mapbox-gl.css'
import styles from '../../modules/map.module.css'

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;


const Map = ({ coords, address }) => {
    const mapContainer = useRef(null);

    const [zoom] = useState(16.5);
    // const [currentLoc, setCurrentLoc] = useState([]);
    const location = [coords.lng, coords.lat]

    useEffect(() => {
        const mapgl = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11',
            center: location,
            zoom: zoom
        });

        new mapboxgl.Marker()
            .setLngLat(location)
            .addTo(mapgl)

        const nav = new mapboxgl.NavigationControl()
        mapgl.addControl(nav)

        if (mapgl) return
    })

    return (
        <div className={styles['wrapper']}>
            <div className={styles['address-card']}>
                <p>{address.location}</p>
                <p>{address.straat}</p>
                <p>{address.plaats}</p>
            </div>
            <div ref={mapContainer} className={styles['map']} />
        </div>
    )
}

export default Map
