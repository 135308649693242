import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './diensten.module.css'
import projectStyles from '../../style.module.css'

import header from '../../assets/headers/diensten.png'
import Container from '../../components/Container';

const Index = () => {

	const [sportEnBewegen, setSportEnBewegen] = useState(true)
	const [arbijd, setArbijd] = useState(true)
	const [lifestyle, setLifestyle] = useState(true)

	const ps = projectStyles
	const s = styles

	return (
		<div className={styles['container']}>
			<Helmet>
				<title>Fysiotherapie beter - Diensten</title>
				<meta property="og:title" content="Fysiotherapie beter" />
			</Helmet>

			<Navigation active='diensten' />

			<Header
				text="Wij leveren fysiotherapeutische zorg, waarbij we in samenspraak met de patiënten, zorgen dat patiënten weer kunnen doen wat ze willen doen."
				heading="Diensten"
				rootClassName="rootClassName"
				image={header} />

			<div style={{ marginTop: '100px' }}></div>
			<Container
				rootClassName='wrapper'
				heading={<h1 className={projectStyles['main-heading']}>Onze diensten</h1>}>

				<div className={styles['leading-text']}>
					<p>Onze diensten zijn niet gericht op langdurige behandeltrajecten, maar gericht op instructies en zoveel als mogelijk zelf doen voor een optimaal herstel. Chronische patiënten wordt geleerd hoe om te gaan met hun beperkingen, de zelfredzaamheid te vergroten en waar mogelijk de leefstijl te veranderen en hierdoor te verbeteren.</p>
				</div>
			</Container>
			<div style={{ marginTop: '100px' }}></div>
			<Container
				rootClassName='wrapper'
				heading={<h1 className={projectStyles['sub-heading']}>Dry Needling</h1>}>

				<div className={ps['grid-two-column']}>
					<div className={s.text01}>
						<p>Dry Needling is een behandelmethode om de spieren die verantwoordelijk zijn voor uw pijn met een fijn naaldje aan te prikken, met als doel de spier snel en langdurig te ontspannen. De fysiotherapeut zal aan de hand van een aantal vragen kijken of deze behandelmethode past bij uw klachten.</p>

						<p>Bij een vermoeden dat de door u genoemde klachten vanuit spieren ontstaan zijn, wordt er gezocht naar de zogenoemde triggerpoints. Een triggerpoint is een verkramping in een gedeelte van een spier. Dit kan lokale drukpijn geven, maar ook uitstralende pijn in een bepaalde zone.</p>

						<p>Triggerpoints kunnen op allerlei verschillende manieren ontstaan. Om enkele voorbeelden te noemen: een verkeerde houding, een overbelasting van bepaalde spieren door sport of langdurig computergebruik, maar ook stress kan een belangrijke onderhoudende factor zijn van een triggerpoint.</p>
					</div>

					<div className={s.text01}>
						<p>Deze bovengenoemde triggerpoints zijn goed te behandelen middels Dry Needling. Dry Needling is gericht op het langdurig uitschakelen van deze punten. Vaak zijn er meerdere sessies nodig, waarin er in meerdere spierpunten geprikt wordt. Dry Needling is onderdeel van een totaalbehandeling.</p>

						<p>Het inprikken van de spier voelt u bijna niet. Als het juiste triggerpoint aangeprikt wordt, kunt u een lokale spierspanning voelen. Dit geeft kortdurend een krampgevoel. Daarna zal de spier ontspannen. Na de behandeling is het normaal dat de behandelde spier nog wat stijf/verkrampt aanvoelt, meestal is dit van korte duur.</p>
					</div>
				</div>
			</Container>
			<div style={{ marginTop: '100px' }}></div>
			<div className={ps['gray-box']}>
				<Container
					rootClassName='wrapper'
					heading={
						<div className={styles['header-section']}>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
								<h1 className={ps['main-heading']} style={{ marginBottom: '20px' }}>Sport en bewegen</h1>
								<p style={{ maxWidth: '600px' }}>Sporten en bewegen is voor iedereen mogelijk. Ongeacht leeftijd, gewicht, geslacht of medische achtergrond</p>
							</div>
							<div className={styles['button-wrapper']}>
								<p className={styles['button']} onClick={() => setSportEnBewegen(!sportEnBewegen)}>{sportEnBewegen ? 'Toon meer' : 'Toon minder'}</p>
							</div>
						</div>
					}>

					<div className={s['gray-text-wrapper']} style={{ display: `${sportEnBewegen ? 'none' : 'flex'}` }}>
						<Container
							rootClassName='sub-wrapper'
							heading={<h1 className={ps['sub-heading']}>Medische fitness</h1>}>

							<div>
								<p>Tijdens de Medische fitness traint u in een kleine groep mensen onder begeleiding van een fysiotherapeut. We trainen één uur waarbij we per individu in samenspraak met u besluiten welke doelen we willen halen en hoe we dat gaan doen.</p>

								<p>Hierbij kan u denken aan het opbouwen van conditie, spierkracht, coördinatie en balans. We maken een schema die speciaal op maat gemaakt is. Dit schema evalueren we samen met u waarna oefeningen worden aangepast.  </p>
							</div>
						</Container>

						<Container
							rootClassName='sub-wrapper'
							heading={<h1 className={ps['sub-heading']}>Individuele training</h1>}>

							<div>
								<p>Onder begeleiding van een fysiotherapeut werken we samen aan uw persoonlijke doelen. Uw hulpvraag kan uiteenlopend zijn. Wilt u uw sportprestaties verbeteren? Gezondheidsrisico’s verlagen? Revalideren na een operatie? Preventief trainen? Preoperatief trainen? Uw huidige beweegmogelijkheden behouden of verfijnen?</p>

								<p>Alles is dus mogelijk! Benieuwd of dit iets voor u zou kunnen zijn en wat bij u past? Neem contact met ons op.</p>
							</div>
						</Container>
					</div>

					<div className={s['gray-text-wrapper']} style={{ display: `${sportEnBewegen ? 'none' : 'flex'}` }}>
						<Container
							rootClassName='sub-wrapper'
							heading={<h1 className={ps['sub-heading']}>Medische Pilates</h1>}>

							<div>
								<p>Tijdens dit uur gaat u in een kleine groep, onder begeleiding van een fysiotherapeut aan de slag met oefeningen die de controle, balans, spierkracht en conditie van het lichaam verbeteren. Dit op basis van de oefeningen die ooit gemaakt zijn door dhr. Pilates.</p>

								<p>Hij ontwikkelde een oefenprogramma, terwijl hij werkte aan de revalidatie van gewonde soldaten. Wij nemen deze oefeningen over en leren zo het lichaam gecontroleerd te trainen.</p>
							</div>
						</Container>
					</div>

				</Container>
			</div>

			<div style={{ marginTop: '100px' }}></div>

			<Container
				rootClassName='wrapper'
				heading={
					<div className={styles['header-section']}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
							<h1 className={ps['main-heading']} style={{ marginBottom: '20px' }}>Arbeidsfysiotherapie</h1>

							<div style={{ maxWidth: '600px' }}>
								<p>Wij zijn al vele jaren actief bij meerdere locaties bij bedrijven met onze fysiotherapeutische interventies. Dit tot behoud van de arbeidsmogelijkheden van de individuele werknemer. </p>

								<p>Een verbinding leggen tussen de fysieke beperking en arbeids houdings-en bewegingsgedrag is een zeer belangrijk deel van ons werk. </p>
							</div>
						</div>
						<div className={styles['button-wrapper']}>
							<p className={styles['button']} onClick={() => setArbijd(!arbijd)}>{arbijd ? 'Toon meer' : 'Toon minder'}</p>
						</div>
					</div>
				}>

				<div className={s['gray-text-wrapper']} style={{ display: `${arbijd ? 'none' : 'flex'}` }}>
					<Container
						rootClassName='sub-wrapper'
						heading={<h1 className={ps['sub-heading']}>Werkplekbezoeken</h1>}>

						<div>
							<p>Onze fysiotherapeuten gaan verder dan alleen hun eigen behandelkamer. Samen met de medewerker kijken we naar de huidige werkplek, wat is er nodig om een verbeterde werkhouding te creëren? Samen gaan wij op zoek naar de juiste afstellingen, inrichting en gebruik van de werkplek. </p>
						</div>
					</Container>

					<Container
						rootClassName='sub-wrapper'
						heading={<h1 className={ps['sub-heading']}>Behandeling</h1>}>

						<div>
							<p>Behandeling is mogelijk bij uw bedrijf op locatie. Neem contact met ons op en vraag naar de mogelijkheden.</p>
						</div>
					</Container>
				</div>

				<div className={s['gray-text-wrapper']} style={{ display: `${arbijd ? 'none' : 'flex'}` }}>
					<Container
						rootClassName='sub-wrapper'
						heading={<h1 className={ps['sub-heading']}>PMO (Preventief medisch onderzoek)</h1>}>

						<div>
							<p>In samenwerking met Beter Doen verzorgen wij Preventief Medisch Onderzoek bij uw bedrijf op locatie.</p>
						</div>
					</Container>
				</div>
			</Container>

			<div style={{ marginTop: '100px' }}></div>

			<div className={ps['gray-box']}>
				<Container
					rootClassName='wrapper'
					heading={
						<div className={styles['header-section']}>
							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
								<h1 className={ps['main-heading']} style={{ marginBottom: '20px' }}>Leefstijl</h1>
								<p style={{ margin: '7px 0', maxWidth: '600px' }}>Fysiotherapie gaat verder dan alleen het fysiek functioneren. Om optimaal te kunnen herstellen of functioneren is een gezonde leefstijl ontzettend belangrijk.</p>

								<p style={{ margin: '7px 0', maxWidth: '600px' }}>Hierbij spelen een aantal facetten een belangrijke rol, zoals voeding, bewegen, ontspanning/stress, slapen en uw medische achtergrond. Deze facetten vormen samen uw leefstijl.</p>

							</div>
							<div className={styles['button-wrapper']}>
								<p className={styles['button']} onClick={() => setLifestyle(!lifestyle)}>{lifestyle ? 'Toon meer' : 'Toon minder'}</p>
							</div>
						</div>
					}>

					<div className={s['gray-text-wrapper']} style={{ display: `${lifestyle ? 'none' : 'flex'}` }}>
						<Container
							rootClassName='sub-wrapper'
							heading={<h1 className={ps['sub-heading']}>GLI</h1>}>

							<div>
								<p>Fysiotherapie Beter biedt Gecombineerde Leefstijlinterventie (GLI) trajecten aan. Wat houdt dat precies in? De GLI is een tweejarig programma, dat speciaal is ontwikkeld voor mensen met overgewicht, die willen afvallen en gemotiveerd zijn om gezonder te gaan leven.</p>

								<p>Deelname gaat altijd in overleg met uw huisarts. U krijgt het programma bij alle zorgverzekeraars volledig vergoed, zonder eigen risico. U krijgt begeleiding van een leefstijlcoach en u doorloopt het programma met een groep van maximaal 12 deelnemers.</p>

								<p>Benieuwd naar wat we voor u kunnen betekenen op het gebied van leefstijl, laat het ons weten.</p>
							</div>
						</Container>
					</div>
				</Container>
			</div>

			<Footer rootClassName="rootClassName2"></Footer>
		</div>
	)
}

export default Index
