const Envelope = ({ color }) => {
    return (
        <div>
            <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 0H0.5C0.223437 0 0 0.223437 0 0.5V10.5C0 10.7766 0.223437 11 0.5 11H13.5C13.7766 11 14 10.7766 14 10.5V0.5C14 0.223437 13.7766 0 13.5 0ZM12.875 1.73125V9.875H1.125V1.73125L0.69375 1.39531L1.30781 0.60625L1.97656 1.12656H12.025L12.6938 0.60625L13.3078 1.39531L12.875 1.73125ZM12.025 1.125L7 5.03125L1.975 1.125L1.30625 0.604687L0.692188 1.39375L1.12344 1.72969L6.46094 5.87969C6.61444 5.99894 6.80328 6.06367 6.99766 6.06367C7.19203 6.06367 7.38088 5.99894 7.53438 5.87969L12.875 1.73125L13.3062 1.39531L12.6922 0.60625L12.025 1.125Z" fill={color} />
            </svg>
        </div>
    )
}
Envelope.defaultProps = {
    color: '#F8F8F8'
}

export default Envelope
