import Header from "../../components/header"
import Navigation from "../../components/navigation"
import Footer from "../../components/footer"

import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

import header from '../../assets/headers/over-ons.png'

import styles from './team-lid.module.css'
import projectStyles from '../../style.module.css'


const TeamLid = ({ lid }) => {
    return (
        <div className={ styles['page-wrapper'] }>
            <Helmet>
                <title>Overons - {lid.name}</title>
                <meta
                    property="og:title"
                    content="Behandelingen - Fysiotherapie beter"
                />
            </Helmet>

            <Navigation active="overons" />

            <Header
                text="Wij leveren fysiotherapeutische zorg, waarbij we in samenspraak met de patiënt, zorgen dat patiënten weer kunnen doen wat ze willen doen."
                heading="Fysiotherapie Beter"
                rootClassName="rootClassName"
                image={header} />

            <div className={styles['wrapper']}>
                <div>
                    <h1 style={{ fontWeight: 500 }}>{lid.name}</h1>
                    <div className={styles['paragraphs']}>
                        {lid.bio.map((p, i) => (
                            <p key={i}>{p}</p>
                        ))}
                    </div>

                    {lid.big !== '' && (<p>BIG: {lid.big}</p>)}

                    <Link to="/overons" className={`${styles['button']} ${projectStyles['button']}`}>Heel het team</Link>
                </div>

                <img src={lid.image.src} alt={lid.image.alt} className={styles['image']} />
            </div>
            <Footer />
        </div>
    )
}

export default TeamLid
