import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Header from '../../components/header'
import Container from '../../components/Container'
import InfoText from '../../components/info-text'
import Footer from '../../components/footer'
import projectStyles from '../../style.module.css'
import styles from './home.module.css'

// images
import header from '../../assets/headers/home.png'
import Phone from '../../components/icons/Phone'
import Envelope from '../../components/icons/Envelope';
import Map from '../../components/MapBox/Map'
// import Map from '../../components/GoogleMap/Map'

import { RightAddress, LeftAddress } from '../../data/addresses'
import WhatsApp from '../../components/icons/WhatsApp';

const Index = () => {
	return (
		<div className={styles['container']}>
			<Helmet>
				<title>Fysiotherapie beter</title>
				<meta property="og:title" content="Fysiotherapie beter" />
			</Helmet>
			<Navigation rootClassName="rootClassName"></Navigation>
			<Header
				text="Wij leveren fysiotherapeutische zorg, waarbij we in samenspraak met de patiënt, zorgen dat patiënten weer kunnen doen wat ze willen doen."
				heading="Fysiotherapie Beter"
				rootClassName="rootClassName"
				image={header} />

			<div style={{ margin: '130px 0' }}>
				<Container
					rootClassName='wrapper'
					style={{ margin: '100px 0' }}
					heading={<h1 className={projectStyles['main-heading']}>Fysiotherapie Beter</h1>}>

					<p>Fysiotherapie Beter is een groeiende fysiotherapiepraktijk, met een jong team. Onze hoofdvestiging zit in het Gezondheidscentrum in Giessenburg, daarnaast hebben we een nevenvestiging in de binnenstad in Gorinchem. Beide locaties beschikken over voldoende behandelkamers en een trainingsruimte.</p>

					<div>
						<p>Wij leveren fysiotherapeutische zorg, waarbij we in samenspraak met de patiënten, zorgen dat patiënten weer kunnen doen wat ze willen doen. Daarnaast is voor ons het voorkomen van klachten (preventie) en het omgaan met klachten een belangrijk aspect. Een goede analyse van uw houdings-en bewegingsapparaat is de beste basis voor uw behandeling.</p>

						<p>Onze kracht? Korte behandeltrajecten en een persoonlijke benadering.</p>
					</div>
				</Container>
			</div>

			<div className={projectStyles['gray-box']}>
				<Container
					rootClassName='wrapper'
					heading={<h1 className={projectStyles['main-heading']}>Expertises / behandelmogelijkheden</h1>}>

					<p style={{ maxWidth: '420px' }}>Wanneer u voor het eerst bij de fysiotherapeut komt, beginnen we met een intakegesprek. Daarin inventariseren we welke klachten u heeft, hoe u daar nu mee omgaat en welke activiteit u daardoor niet of moeilijk meer kunt uitvoeren. Hiernaast zal ook een lichamelijk onderzoek plaatsvinden. Met deze gegevens zullen we in samenspraak met u een behandelplan opstellen.Tussentijds evalueren we samen de resultaten en stellen we het behandelplan zo nodig bij.</p>
				</Container>

				<Container
					rootClassName='wrapper'
					heading={<h1 className={projectStyles['sub-heading']}>Medische fitness</h1>}>

					<span style={{ maxWidth: '420px' }}>
						<p>Tijdens de medische fitness traint u in een kleine groep mensen onder begeleiding van een fysiotherapeut. We trainen één uur waarbij we in samenspraak met u besluiten welke doelen we willen halen en hoe we dat gaan doen.</p>

						<p>Hierbij kan u denken aan het opbouwen van conditie, spierkracht, coördinatie en balans. We maken een schema dat speciaal op maat gemaakt is. Dit schema evalueren we samen met u waarna oefeningen worden aangepast.</p>
					</span>
				</Container>
			</div>

			<div className={styles['contact-info']}>
				<div className={styles['container04']}>
					<h1 className={` ${projectStyles['main-heading']} `}>Contact</h1>

					<div className={styles['container05']}>
						<div style={{ marginTop: '30px' }}>
							<InfoText icon={<Phone color="#347A81" />} text='0184 745906' />
							<InfoText icon={<WhatsApp color="#347A81" />} text='06 13966954' />
							<InfoText icon={<Envelope color="#347A81" />} />
						</div>
					</div>
				</div>

				<div className={styles['openingstijden']}>
					<h1 className={` ${projectStyles['section-heading']} `}>Openingstijden</h1>
					<div className={styles['container8']} style={{ marginTop: '70px' }}>
						<div className={styles['tijden']}>
							<p>Maandag</p>
							<p>Dinsdag</p>
							<p>Woensdag</p>
							<p>Donderdag</p>
							<p>Vrijdag</p>
						</div>
						<div className={styles['tijden']}>
							<p>08:00 - 17:30</p>
							<p>08:00 - 20:30</p>
							<p>08:00 - 20:30</p>
							<p>08:00 - 17:30</p>
							<p>08:00 - 17:00</p>
						</div>
					</div>
				</div>
			</div>

			<div className={styles['maps-wrapper']} style={{ margin: '' }}>
				<h1 className={projectStyles['section-heading']} style={{ marginBottom: '20px' }}>Onze locaties</h1>

				<div className={styles['maps']}>
					<Map coords={LeftAddress.coords} address={LeftAddress.address} />
					<Map coords={RightAddress.coords} address={RightAddress.address} />
				</div>
			</div>

			<Footer />
		</div>
	)
}

export default Index
