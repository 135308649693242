import React from 'react'

import PropTypes from 'prop-types'

import styles from '../modules/header.module.css'

const Header = ({ heading, text, image, rootClassName }) => {
  return (
    <div className={` ${styles['container']} ${styles['bg-image']} ${styles[rootClassName]} `} style={{ background: `url(${image})` }}>
      <div className={styles['text-wrapper']}>
        <h1 className={styles['text']}>{heading}</h1>
        <p className={styles['Text1']}>{text}</p>
      </div>
      <div className={styles['bg-gradient']}></div>
    </div>
  )
}

Header.defaultProps = {
  text: 'Text',
  rootClassName: '',
  heading: 'Heading',
}

Header.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  heading: PropTypes.string,
}

export default Header
