import thomas from '../assets/team-leden/tomas.png'
import lisanne from '../assets/team-leden/lisanne.png'
import naomi from '../assets/team-leden/naomi.png'
import lois from '../assets/team-leden/lois.png'
import renate from '../assets/team-leden/renate.jpg'

const url = '/overons/team/'

export const Thomas = {
    name: 'Tomas Henke',
    image: {
        src: thomas,
        alt: 'Tomas Henke'
    },
    bio: [
        'Mijn naam is Tomas Henke. Na mijn sportopleiding, ben ik in 2015 afgestudeerd en werkzaam als fysiotherapeut bij Fysiotherapie Beter.',

        'Samen met mijn patiënten probeer ik tot een zo effectief mogelijk behandelplan te komen, waarin de hulpvraag, algemene dagelijkse levensverrichtingen (ADL), werk en vrijetijdsbesteding een belangrijke rol spelen.',

        'Naast mijn werkzaamheden op de locatie in Giessenburg, ben ik ook meerdere dagdelen in de week werkzaam als arbeidsfysiotherapeut bij bedrijven in de Alblasserwaard.'
    ],
    url: `${url}tomas-henke`,
    big: '39920340504',
}

export const Lisanne = {
    name: 'Lisanne Boogaard-Klein',
    image: {
        src: lisanne,
        alt: 'Lisanne Boogaard-Klein'
    },
    bio: [
        'Mijn naam is Lisanne Boogaard-Klein, sinds november 2019 ben ik werkzaam bij Fysiotherapie Beter als algemeen fysiotherapeut. Tijdens mijn studie heb ik mij, naast de algemene fysiotherapie, veel gericht op de revalidatie bij mensen met een niet aangeboren hersenletsel.',

        'Hierbij kunt u denken aan mensen die een hersenbloeding/infarct hebben doorgemaakt, mensen met de ziekte van Parkinson en/of MS/ALS. Wat ik zo leuk vind aan het vak fysiotherapie? Het helpen van mensen met een probleem aan het bewegingsapparaat, ik zet de patiënt op nummer 1.',

        'In samenwerking met u zoek ik naar de beste behandeling voor uw klacht. Daarnaast vind ik ook de afwisseling erg leuk, er is geen patiënt hetzelfde.Ik kijk ernaar uit om kennis met u te maken!'
    ],
    url: `${url}lisanne-boogaard-klein`,
    big: '79926789204',
}

export const Naomi = {
    name: 'Naomi van der Linden',
    image: {
        src: naomi,
        alt: 'Naomi van der Linden'
    },
    bio: [
        'Voor fysiotherapie Beter werk ik zowel in Giessenburg als Gorinchem als op bedrijven. Naast mijn diploma fysiotherapie, heb ik mijn propedeuse in Social work behaald. Deze combinatie zie ik als een verrijking in mijn werk. Zo heb ik geleerd oog te hebben voor de hele mens, waardoor ik een zo passend mogelijke behandeling kan geven. Daarnaast is het leuk om zoveel verschillende soorten mensen met uiteenlopende klachten te leren kennen en een tijdje met ze op te trekken.',

        'Mijn doel is om mensen weer vertrouwen in het functioneren van hun lichaam terug te geven door inzicht te geven in het ontstaan van hun klachten en ze te helpen zo goed mogelijk te herstellen. Zo kunnen ze hun dagelijkse bezigheden, werk, sport en hobby\'s oppakken op een manier die het risico op toekomstige klachten verkleint. Dat doe ik altijd in samenspraak met de mensen die voor me zitten. Daarnaast zorg ik dat ik op de hoogte ben van ontwikkelingen binnen de fysiotherapie, zodat ik mensen zo goed mogelijk kan helpen.',

        'Mijn doel is altijd om mensen weer vertrouwen in hun lichaam terug te geven door inzicht te geven in het ontstaan van hun klachten en ze te helpen zo goed mogelijk te herstellen. Dat doe ik altijd in samenspraak met de mensen die voor me zitten. Daarnaast zorg ik dat ik op de hoogte ben van ontwikkelingen binnen de fysiotherapie, zodat ik mensen zo goed mogelijk kan helpen. Ik zie er naar uit om u te ontmoeten!'
    ],
    url: `${url}naomi-van-der-linden`,
    big: '69925330704',
}

export const Renate = {
    name: 'Renate van Schooten',
    image: {
        src: renate,
        alt: 'Renate van Schooten'
    },
    bio: [
        'Sinds januari 2022 ben ik werkzaam als administratief medewerker bij Fysiotherapie Beter. Op maandag-, woensdag- en donderdagochtend is de kans groot dat u mij treft in de praktijk in Giessenburg. Ik ben onder andere het aanspreekpunt voor de patiënten. Het is prettig op deze manier iets voor mensen te kunnen betekenen. Met elkaar zorgen we ervoor dat we de patiënt op korte termijn zo goed mogelijk van dienst kunnen zijn en kunnen helpen naar verbetering van de fysieke gezondheid. Welkom!'
    ],
    url: `${url}renate-van-schooten`,
    big: '',
}

export const Lois = {
    name: 'Lois Koster',
    image: {
        src: lois,
        alt: 'Lois Koster'
    },
    bio: [
        'Mijn naam is Lois Koster en ben sinds november 2020 werkzaam bij Fysiotherapie Beter. Ik ben werkzaam in Giessenburg en op onze locatie in Gorinchem. In 2017 heb ik mijn diploma fysiotherapie behaald op Avans hogeschool in Breda.',

        'Daarna heb ik op verschillende plekken werkervaring opgedaan als waarneemster, maar ook als werknemer in loondienst. Ik vind het leuk om mijzelf te ontwikkelen door mijn kennis te verbreden. Naast werkzaam te zijn als humaan fysiotherapeut ben ik bezig met een studie dierfysiotherapie en hoop ik in 2021 mijn diploma te halen.',

        'Ik vind de combinatie mens en dier ontzettend leuk. Als fysiotherapeute vind ik het belangrijk om tot goede sociale vaardigheden en vakinhoudelijke kennis te beschikken. Ik vind het een leuke uitdaging om mensen te adviseren en te helpen, zodat ze klachtenvrij verder kunnen in het dagelijks leven. Ik hoop u snel te ontmoeten bij Fysiotherapie Beter.'
    ],
    url: `${url}lois-koster`,
    big: '19923601804',
}