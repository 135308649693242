import React from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Header from '../../components/header'
import Footer from '../../components/footer'
import styles from './behandelingen.module.css'
import projectStyles from '../../style.module.css'

import header from '../../assets/headers/behandeling.png'
import Expertises from '../../components/Expertises'
import Container from '../../components/Container';

const Index = () => {
	return (
		<div className={styles['container']}>
			<Helmet>
				<title>Behandelingen - Fysiotherapie beter</title>
				<meta
					property="og:title"
					content="Behandelingen - Fysiotherapie beter"
				/>
			</Helmet>

			<Navigation rootClassName="rootClassName1" active='behandelingen' />

			<Header
				heading="Behandelingen"
				text="Wij vinden het belangrijk dat iedereen gezien en geholpen wordt."
				image={header} />


			<div style={{ margin: '70px auto' }}></div>

			<Container
				rootClassName='wrapper'
				heading={<h1 className={projectStyles['main-heading']}>Expertises / behandelmogelijkheden</h1>}>

				<div style={{ width: '100%' }}>
					<p>Wanneer u voor het eerst bij de fysiotherapeut komt, beginnen we met een intakegesprek. Daarin inventariseren we welke klachten u heeft, hoe u daar nu mee omgaat en welke activiteit u daardoor niet of moeilijk meer kunt uitvoeren. </p>

					<p>Hiernaast zal ook een lichamelijk onderzoek plaatsvinden. Met deze gegevens zullen we in samenspraak met u een behandelplan opstellen.Tussentijds evalueren we samen de resultaten en stellen we het behandelplan zo nodig bij.</p>
				</div>

				<div style={{ width: '100%' }}>
					<p>Wij streven ernaar om in overleg met u een zo efficiënt mogelijk de behandeltraject te maken zodat u weer kan doen wat u wil doen. Een behandeling kan bestaan uit het vergroten van bewegingsmogelijkheden, verminderen van spierspanning, vergroten van de spierkracht, het verbeteren van de conditie en het trainen van de balans.</p>

					<p>Daarnaast kan er zo nodig gebruik worden gemaakt van medical taping en kan er in de sportschool worden getraind met apparaten of losse materialen.</p>
				</div>
			</Container>

			<Expertises />

			<Footer rootClassName="rootClassName4" />
		</div>
	)
}

export default Index
