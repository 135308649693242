import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import Navigation from '../../components/navigation'
import Header from '../../components/header'
import Card from '../../components/Card'
import Footer from '../../components/footer'
import styles from './overons.module.css'
import projectStyles from '../../style.module.css'

// images
import header from '../../assets/headers/over-ons.png'

// Vacatures
import vaccature16Uur from '../../assets/vacatures/Vacature_fysiotherapeut_8-16_uur_versie_2.pdf'
import vaccature32Uur from '../../assets/vacatures/Vacature_fysiotherapeut_32_uur.pdf'

import { Thomas, Lisanne, Naomi, Lois, Renate } from '../../data/team-leden'
import Container from '../../components/Container';
import { Link } from 'react-router-dom'
import Download from '../../assets/icons/Download'

const Index = () => {

    const [firstAccordion, setFirstAccordion] = useState(true)
    const [secondAccordion, setSecondAccordion] = useState(true)

    const eersteRij = [
        Thomas,
        Lisanne,
        Naomi
    ]

    const tweedeRij = [
        Renate,
        Lois
    ]

    const classes = {
        textDecoration: 'none',
        padding: '20px',
        background: '#225358',
        minWidth: '210px',
        width: '50%',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '20px'
    }

    return (
        <div className={styles['container']}>
            <Helmet>
                <title>Fysiotherapie beter - Over ons</title>
                <meta property="og:title" content="Fysiotherapie beter" />
            </Helmet>
            <Navigation active="overons" />
            <Header
                text="Wij leveren fysiotherapeutische zorg, waarbij we in samenspraak met de patiënt, zorgen dat patiënten weer kunnen doen wat ze willen doen."
                heading="Fysiotherapie Beter"
                rootClassName="rootClassName"
                image={header} />
            <div className={styles['container1']}>
                <div className={styles['het-team-header']}>
                    <h1 style={{ margin: '0', padding: '0 0 10px', borderBottom: '3px solid #347A81', width: '140px', fontWeight: 500 }}>Het team</h1>
                    <p style={{ margin: '25px 0 0', maxWidth: '400px' }}>Wij bij Fysiotherapie Beter stellen ons graag aan je voor. Leer ons hele team kennen!</p>
                </div>
                <div className={styles['TeamMembers']}>
                    {eersteRij.map(lid => (
                        <Card key={lid.big} name={lid.name} image_alt={lid.name} image_src={lid.image.src} url={lid.url} />
                    ))}
                </div>

                <div className={`${styles['TeamMembers1']} ${styles['gray-space']}`}>
                    {tweedeRij.map(lid => (
                        <Card key={lid.big} name={lid.name} alt={lid.name} image_src={lid.image.src} url={lid.url} />
                    ))}
                </div>
            </div>

            <Container
                rootClassName='wrapper'
                heading={
                    <div className={styles['header-section']}>
                        <h1 className={projectStyles['main-heading']}>Samenwerkingspartners</h1>
                        <div className={styles['button-wrapper']}>
                            <p className={`${styles['button']} ${styles['button-top']}`} onClick={() => setFirstAccordion(!firstAccordion)}>{firstAccordion ? 'Toon meer' : 'Toon minder'}</p>
                        </div>
                    </div>
                }>

                <div className={styles['content-wrapper']}>
                    <div className={styles['cols-2']}>
                        <div>
                            <p>Wij werken nauw samen met verschillende partijen bij ons in het Gezondheidscentrum in Giessenburg. Dit vinden wij een belangrijke meerwaarde voor een zo volledig mogelijke behandeling van onze patiënten.</p>

                            <p>Zo hebben wij een goede samenwerking met de huisartsen bij ons in het Gezondheidscentrum. Hier zijn korte lijntjes en daardoor kunnen we snel en makkelijk overleggen over bepaalde behandelplannen.</p>
                        </div>

                        <div>
                            <p>Daarnaast zijn we zeer regelmatig te vinden in de sportschool van Beter Doen Fitness Health Lifestyle. Hier vinden onze Medisch fitness en Medisch pilates groepen plaats. Ook met onze 1 op 1 behandelingen (bijvoorbeeld voor revalidatie) maken wij hier veel gebruik van. Ook dit is een echte meerwaarde voor de behandeling en gezondheid van onze patiënten.</p>

                            <p>Ditzelfde geldt voor de sportschool, KUS33, bij onze locatie in Gorinchem aan de Westwagenstraat.</p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <p className={`${styles['button']} ${styles['button-bottom']}`} onClick={() => setFirstAccordion(!firstAccordion)}>{firstAccordion ? 'Toon meer' : 'Toon minder'}</p>
                        </div>
                    </div>

                    <div className={styles['cols-2']} style={{ display: `${firstAccordion ? 'none' : 'grid'}` }}>
                        <div>
                            <h1 className={projectStyles['sub-heading']}>Beter doen</h1>

                            <p>In samenwerking met Beter Doen, helpen wij mensen weer fit te worden. Dit doen we door met mensen te werken aan hun conditie, spierkracht en balans in de nieuwe sportschool. Daarbij houden we nauw contact  met leefstijlcoaches en personal trainers.</p>

                            <p>We werken ook samen in het Gecombineerde Leefstijl Interventie project. Hierbij leren we mensen om een gezonde leefstijl te creëren en vol te houden.</p>
                        </div>

                        <div>
                            <h1 className={projectStyles['sub-heading']}>Huisartsen</h1>

                            <p>Binnen het gezondheidscentrum werken de huisartsen en fysiotherapeuten nauw samen om de diagnostiek en het behandelplan zo goed mogelijk met elkaar af te stemmen. Door deze korte lijntjes zijn afspraken snel gemaakt en bent u snel geholpen.</p>
                        </div>

                    </div>
                </div>
            </Container>

            <div className={styles['gray-space']}>
                <div className={styles['wrapper']}>
                    <div className={styles['header-section']}>
                        <h1 className={projectStyles['sub-heading']}>Zorgverleners binnen <br /> het gezondheidscentrum Giessenburg</h1>
                        <div className={styles['button-wrapper']}>
                            <p className={`${styles['button']} ${styles['button-top']}`} onClick={() => setSecondAccordion(!secondAccordion)}>{secondAccordion ? 'Toon meer' : 'Toon minder'}</p>
                        </div>
                    </div>

                    <div className={styles['cols-2']} style={{ marginBottom: 0 }}>
                        <div>

                            <p>Binnen het gezondheidscentrum werken verschillende disciplines samen. Denk hierbij aan de huisartsen, de POH'ers, de thuiszorg, de psychologen, de pedicure, de podologe, de tandartsen, de haptonoom, de personal trainers en de leefstijlcoach.</p>

                            <p>Zo nodig stemmen we het behandelplan op elkaar af. Hierdoor maken we gebruik van alle kennis en kunde die in het gezondheidscentrum aanwezig is en wordt u zo passend mogelijk geholpen.</p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <p className={`${styles['button']} ${styles['button-bottom']}`} onClick={() => setSecondAccordion(!secondAccordion)}>{secondAccordion ? 'Toon meer' : 'Toon minder'}</p>
                        </div>

                        <div style={{ display: `${secondAccordion ? 'none' : 'block'}` }}>
                            <h1 className={projectStyles['sub-heading']}>KUSS33</h1>

                            <p>KUSS33 is gevestigd op de Westwagenstraat in Gorinchem. Op deze locatie kunnen we zowel in de behandelkamer als in de sportschool mensen ontvangen en begeleiden op hun weg naar een gezonder lichaam. </p>

                            <p>Ook hier werken wij samen met de leefstijlcoach, voedingsdeskundige en personal trainer.</p>
                        </div>

                        <div style={{ display: `${secondAccordion ? 'none' : 'block'}` }}>
                            <h1 className={projectStyles['sub-heading']}>Gemeente Molenlanden</h1>

                            <p>In samenwerking met Gemeente Molenlanden zijn wij partner van het preventieakkoord en sportakkoord dat getekend is in het najaar 2021. Met dit akkoord willen wij meehelpen aan een gezonde gemeente waarbij wordt gewerkt aan preventie en positieve gezondheid van de inwoners van gemeente Molenlanden.</p>

                            <p>Op dit moment worden de plannen verder uitgewerkt. Hierover zal u binnenkort via diverse media worden geïnformeerd.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles['wrapper']} style={{ padding: '100px 0' }}>
                <h1 className={projectStyles['sub-heading']} style={{ width: '320px', marginBottom: '15px' }}>Kom jij ons team versterken?</h1>

                <div className={styles['cols-2']} style={{ marginBottom: 0 }}>

                    <div style={{ padding: '10px 0' }}>
                        <p style={{ margin: 0 }}>Bij Fysiotherapie Beter werken wij met passie! Ons jonge en dynamische team van fysiotherapeuten heeft zich gespecialiseerd in uiteenlopende behandelmethodes. Help jij ons mee om elke dag betere zorg te leveren? Reageer dan op één van de vacatures!</p>
                    </div>

                    <div style={{ padding: '10px 0' }}>
                    <Link to={vaccature16Uur} target="_blank" download style={classes}>
                            Fysiotherapeut 8 - 16 uur
                            <Download />
                        </Link>

                        <Link to={vaccature32Uur} target="_blank" download style={classes}>
                            Fysiotherapeut 32 uur
                            <Download />
                        </Link>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Index
