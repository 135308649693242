export const RightAddress = {
    coords: {
        lat: 51.83046,
        lng: 4.97058
    },
    address: {
        location: 'Dependance: Fysiotherapie Beter',
        straat: 'Westwagenstraat 69',
        plaats: '4201 HE Gorinchem'
    }
}

export const LeftAddress = {
    coords: {
        lat: 51.8784,
        lng: 4.8296
    },
    address: {
        location: 'Dependance: Fysiotherapie Beter',
        straat: 'Graafdijk Oost 37',
        plaats: '2973 XC Molenaarsgraaf'
    }
}