import React from 'react'

import PropTypes from 'prop-types'

import styles from '../modules/footer.module.css'
import Envelope from './icons/Envelope'
import Phone from './icons/Phone'
import logo from '../assets/logo-white.svg'
import WhatsApp from './icons/WhatsApp';

const Footer = (props) => {
	return (
		<div className={` ${styles['footer-wrapper']} ${styles[props.rootClassName]} `}>
			<div className={styles['content']}>
				<div className={styles['logo-wrapper']}>
					<p className={styles['fysio-heading']}>Fysiotherapie Beter</p>
					<p className={styles['kvk']}>KvK: 84164549</p>
					<img src={logo} alt="Logo" />
				</div>

				<div className={styles['content-wrapper']}>
					<div className={styles['locaties']}>
						<span className={styles['footer-heading']}>
							<span>Locaties</span>
						</span>
						<div className={styles['container2']}>
							<span className={styles['text06']}>
								<span>Kastanjeplein 11</span>
								<br></br>
								<span>3381 LT Giessenburg</span>
							</span>
							<span className={styles['text02']}>
								<span>Westwagenstraat 69</span>
								<br></br>
								<span>4201 HE Gorinchem</span>
							</span>
							<span className={styles['text02']}>
								<span>Graafdijk Oost 37</span>
								<br></br>
								<span>2973 XC Molenaarsgraaf</span>
							</span>
						</div>
					</div>
					<div className={styles['container3']}>
						<span className={styles['footer-heading']}>
							<span>Contact</span>
						</span>
						<div className={styles['contact']}>
							<div className={styles['info']}>
								<Phone />
								<p className={styles['contact-text']}>
									<span>: 0184 745906</span>
								</p>
							</div>
							<div className={styles['info']}>
								<WhatsApp />
								<p className={styles['contact-text']}>
									<span>: 06 13966954</span>
								</p>
							</div>
							<div className={styles['info']}>
								<Envelope />
								<p className={styles['contact-text']}>
									<span>: info@fysiotherapiebeter.nl</span>
								</p>
							</div>
						</div>
					</div>
					<div className={styles['openings-tijden']}>
						<span className={styles['footer-heading']}>
							<p>Openingstijden:</p>
						</span>
						<div className={styles['container8']}>
							<div className={styles['text18']}>
								<p>Maandag</p>
								<p>Dinsdag</p>
								<p>Woensdag</p>
								<p>Donderdag</p>
								<p>Vrijdag</p>
							</div>
							<div className={styles['text28']}>
								<p>08:00 - 17:30</p>
								<p>08:00 - 20:30</p>
								<p>08:00 - 20:30</p>
								<p>08:00 - 17:30</p>
								<p>08:00 - 17:00</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

Footer.defaultProps = {
	rootClassName: '',
}

Footer.propTypes = {
	rootClassName: PropTypes.string,
}

export default Footer
