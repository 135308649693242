import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import './style.module.css'
import Behandelingen from './views/behandelingen/Index.jsx'
import Home from './views/home/Index.jsx'
import Contactons from './views/contactons/Index.jsx'
import Overons from './views/overons/Index.jsx'
import Diensten from './views/diensten/Index.jsx'
import { Thomas, Lisanne, Naomi, Renate, Lois } from './data/team-leden';
import TeamLid from './views/overons/TeamLid';

const App = () => {

	const leden = [
		Thomas,
		Lisanne,
		Naomi,
		Renate,
		Lois
	]

	return (
		<Router>
			<Switch>
				<Route exact path="/"><Home /></Route>
				<Route exact path="/behandelingen"><Behandelingen /></Route>
				<Route exact path="/contactons"><Contactons /></Route>
				{leden.map(lid => (
					<Route key={lid.big} exact path={lid.url}><TeamLid lid={lid} /></Route>
				))}
				<Route exact path="/overons"><Overons /></Route>
				<Route exact path="/diensten"><Diensten /></Route>
			</Switch>
		</Router>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))