import React from 'react'

import PropTypes from 'prop-types'

import styles from '../modules/container.module.css'

const Container = (props) => {
	return (
		<div className={` ${styles['container']} ${styles[props.rootClassName]} `}>
			<div className={styles['heading-section']}>
				{props.heading}
			</div>

			<div className={styles['text-wrapper']}>
				{props.children}
			</div>
		</div>
	)
}

Container.defaultProps = {
	heading: '',
	rootClassName: '',
	headingStyle: ''
}

Container.propTypes = {
	heading: PropTypes.element,
	rootClassName: PropTypes.string,
	headingStyle: PropTypes.string
}

export default Container
